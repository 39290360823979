import { useState } from 'react';

import { SHOffer } from '../services/sortingHat/types';
import { ExtraFunctionsType, UserType } from '../types';

import { sendFireboltSoftProposal } from '../services/firebolt';

import { redirect } from '../helpers/redirect';
import { getUrl } from '../helpers/getRedirectUrl';

type UseOfferCardProps = {
  offer: SHOffer;
  user: UserType;
  extraFunctions?: ExtraFunctionsType;
  hasTestAB?: boolean;
};

export function useOfferRedirect({
  offer,
  user,
  extraFunctions,
  hasTestAB,
}: UseOfferCardProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRedirectModalOpen, setIsRedirectModalOpen] = useState(false);

  async function handleClickOffer() {
    setIsLoading(true);
    setIsRedirectModalOpen(true);
    extraFunctions?.beforeSendProposal?.();

    const { generalInfo } = offer;
    const { slug, partner } = generalInfo;
    const isAc = partner === 'acordocerto';

    if (isAc) return;

    const proposalResponse = await sendFireboltSoftProposal({
      ...user,
      chosenCard: slug,
      partner,
    });

    const fireboltId = proposalResponse?.firebolt_id;
    const fireboltSourceUrl = proposalResponse?.webhookResult?.redirectUrl;
    const storyblokSourceUrl = await getUrl({ offer, fireboltId, user });

    extraFunctions?.afterSendProposal?.(fireboltId);

    // region TEST AB - New BB-Ourocard Flow
    const isBBTest = slug === 'cards-bancodobrasil-ourocardfacil' && hasTestAB;
    const replaceBBUrl = (url: string) => {
      const newUrl = new URL(url.replace('produto', 'produtos'));
      newUrl.searchParams.set('new-flow', 'bb');

      return newUrl.toString();
    };
    const bbUrl =
      isBBTest && storyblokSourceUrl ? replaceBBUrl(storyblokSourceUrl) : null;
    // endregion

    const url = bbUrl || fireboltSourceUrl || storyblokSourceUrl;

    extraFunctions?.beforeRedirect?.(fireboltId, url);

    redirect(url);
    setIsRedirectModalOpen(false);
    setIsLoading(false);

    extraFunctions?.afterRedirect?.(fireboltId, url);
  }

  return {
    isLoading,
    handleClickOffer,
    isRedirectModalOpen,
  };
}
