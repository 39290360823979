import {
  createContext,
  useState,
  useEffect,
  useRef,
  SetStateAction,
  Dispatch,
} from 'react';
import {
  PartnerSystems,
  SortingDebtResponse,
} from '@common/apis/sorting-debt/types';
import { formatSDResponseCache } from './helpers';

import { cacheSortingDebtIsValid } from '@common/hooks/useDebts/helpers';
import { useUserStore } from '@common/store/useUserStore';
import {
  legacyBase64Decrypt,
  legacyBase64Encrypt,
} from '@libs/utils/helpers/base64';
import constants from '@common/constants';
import { useRedline } from '@libs/redline';
import useApiSortingDebt from '@common/apis/sorting-debt/useApiSortingDebt';
import { useDeviceSize } from '@libs/utils/hooks/useDeviceSize';
import useAuth from '@common/hooks/useAuth';
import useDebtDiscountCountdown from '@common/hooks/useDebtDiscountCountdown';

import { DebtFilter, FormattedDebt } from './types';
import {
  getCookie,
  removeCookie,
} from '@libs/utils/helpers/getSafeCookies';
import { getDebtCardTagDiscountDrop } from '@common/helpers/getDebtCardTagDiscountDrop';

import { base64Decrypt } from '@libs/utils/helpers/base64';
import { pageNames } from '@common/routes/pageNames';
import useGTM from '@common/hooks/useGTM';

import useDebtsFiltering from './hooks/useDebtsFiltering';
import useDebtsOrdering from './hooks/useDebtsOrdering';

interface DebtContextType {
  debtsList: FormattedDebt[];
  debtsListSemiSkeletons: any;
  skeletonsCards: any;
  isStatusPartial: boolean;
  noDebts: boolean;
  filteredDebts: FormattedDebt[];
  filterList: DebtFilter[];
  notFoundDebtsText?: string;
  clearFilter: () => void;
  clearNotFoundDebtsMsg: () => void;
  selectFilter: (filter: DebtFilter) => void;
  selectedFilter: DebtFilter | null;
  hasFilter: boolean;
  countdownTimer: string;
  hasRedirectTo: boolean;
  setHasRedirectTo: Dispatch<SetStateAction<boolean>>;
  hasDueDate: boolean;
  setHasDueDate: Dispatch<SetStateAction<boolean>>;
  hasAlreadyRedirected: boolean;
  setHasAlreadyRedirected: Dispatch<SetStateAction<boolean>>;
  hasDebtsApiError: boolean;
  loadNewDebtsInLastPos: () => void;
  loadNewDebtsInRegularPos: () => void;
  isModalOfferOpen: boolean; 
  setIsModalOfferOpen: Dispatch<SetStateAction<boolean>>; 
}

// TODO - move all this logic to ./hooks/index.ts
export const DebtsContext = createContext<DebtContextType>({
  debtsList: [],
  debtsListSemiSkeletons: undefined,
  skeletonsCards: undefined,
  hasDebtsApiError: false,
  isStatusPartial: true,
  noDebts: false,
  filteredDebts: [],
  filterList: [],
  clearFilter: () => { },
  selectFilter: (filter: DebtFilter) => { },
  clearNotFoundDebtsMsg: () => { },
  selectedFilter: null,
  hasFilter: false,
  countdownTimer: '',
  hasRedirectTo: false,
  setHasRedirectTo: () => { },
  hasDueDate: false,
  setHasDueDate: () => { },
  hasAlreadyRedirected: false,
  setHasAlreadyRedirected: () => { },
  loadNewDebtsInLastPos: () => { },
  loadNewDebtsInRegularPos: () => { },
  isModalOfferOpen: false,
  setIsModalOfferOpen: () => { }, 
});

export const DebtsProvider = ({ children }: any) => {
  const { logOut, validToken } = useAuth();
  const user = useUserStore((state) => state.user);

  const { isMobile } = useDeviceSize();
  const GTM = useGTM();
  const { track: trackRedline } = useRedline();
  const { apiSortingDebtTrack } = useApiSortingDebt();

  const firstRequestSortingDebt = useRef(true);
  const isPollingActive = useRef(false);
  const [debtsPollingIntervalId, setDebtsPollingIntervalId] =
    useState<any>(null);

  const [debtsList, setDebtsList] = useState<FormattedDebt[]>([]);
  const [debtsListSemiSkeletons, setDebtsListSemiSkeletons] =
    useState<SortingDebtResponse>();
  const [skeletonsCards] = useState(() => [...Array(isMobile ? 1 : 4).keys()]);
  const [isStatusPartial, setIsStatusPartial] = useState<boolean>(true);
  const [noDebts, setNoDebts] = useState<boolean>(false);
  const [hasRedirectTo, setHasRedirectTo] = useState<boolean>(false);
  const [hasAlreadyRedirected, setHasAlreadyRedirected] =
    useState<boolean>(false);
  const [hasDebtsApiError, setHasDebtsApiError] = useState<boolean>(false);
  const sortingDebtsCallTimes = useRef(0);

  const [hasDueDate, setHasDueDate] = useState<boolean>(false);

  const [isModalOfferOpen, setIsModalOfferOpen] = useState<boolean>(false); 

  const {
    filteredDebts,
    filterList,
    clearFilter,
    selectFilter,
    selectedFilter,
    hasFilter,
    notFoundDebtsText,
    clearNotFoundDebtsMsg,
  } = useDebtsFiltering({ fullDebtList: debtsList, isStatusPartial });

  const { orderedDebts, loadNewDebtsInLastPos, loadNewDebtsInRegularPos } =
    useDebtsOrdering({ debtsList });
  const { countdownTimer } = useDebtDiscountCountdown(isStatusPartial, noDebts);

  const filterSemiSkeletonCards = (
    responseSortingDebts: SortingDebtResponse
  ) => {
    return {
      ...responseSortingDebts,
      partnerSystems: responseSortingDebts?.partnerSystems?.filter(
        (partner) => partner?.debts?.length === 0
      ),
    };
  };

  const checkDebtsUpdate = (
    currentResponseSortingDebts: any,
    debtlist: any
  ) => {
    if (!currentResponseSortingDebts || !debtlist) return true;

    const hasMatchingId = (
      elementA: PartnerSystems,
      elementB: PartnerSystems
    ) => {
      return elementA?.id === elementB?.id;
    };

    const hasAllElementsMatching = (
      sourceList: PartnerSystems[],
      targetList: PartnerSystems[]
    ) => {
      return sourceList?.every((sourceElement) =>
        targetList?.some((targetElement) =>
          hasMatchingId(sourceElement, targetElement)
        )
      );
    };

    const hasAllDebtsInTheDebtList =
      hasAllElementsMatching(currentResponseSortingDebts, debtlist) &&
      hasAllElementsMatching(debtlist, currentResponseSortingDebts);

    return !hasAllDebtsInTheDebtList;
  };

  const handleRedirectToCookie = () => {
    const redirectToCookie = getCookie(constants.cookies.REDIRECT_TO);

    if (!redirectToCookie) return undefined;

    try {
      const decryptData = base64Decrypt(redirectToCookie);
      const redirectData = JSON.parse(decryptData);

      return redirectData;
    } catch {
      console.error(
        "[handleRedirectToCookie] - Error decoding or parsing cookie 'redirect_to'"
      );
      removeCookie(constants.cookies.REDIRECT_TO);

      return undefined;
    }
  };

  const formattingPartnersInDebts = (partnerSystems: PartnerSystems[]) => {
    const redirectTo = handleRedirectToCookie();

    const debtsWithPartner = partnerSystems?.reduce(
      (formattedDebts, partnerSystem) => {
        const { debts: partnerDebts, ...partnerProps } = partnerSystem;
        const formattedPartnerDebts = partnerDebts.map((debt) => {
          return {
            partner: partnerProps,
            ...debt,
            ...(!!redirectTo && { redirectTo }),
          };
        });

        return [...formattedDebts, ...formattedPartnerDebts];
      },
      [] as FormattedDebt[]
    );

    return debtsWithPartner;
  };

  const handleUpdateDebtsList = (responseSortingDebts: SortingDebtResponse) => {
    setIsStatusPartial(responseSortingDebts.status === 'PARTIAL');

    const currentDebtsListSemiSkeletons =
      filterSemiSkeletonCards(responseSortingDebts);

    const hasDebtsSkeletonUpdated = checkDebtsUpdate(
      currentDebtsListSemiSkeletons?.partnerSystems,
      debtsListSemiSkeletons?.partnerSystems
    );

    if (hasDebtsSkeletonUpdated)
      setDebtsListSemiSkeletons(currentDebtsListSemiSkeletons);

    const formattingSortingDebt = formattingPartnersInDebts(
      responseSortingDebts?.partnerSystems
    );

    sendEventDebtSimulated(formattingSortingDebt);
    sendEventDebtLocated(responseSortingDebts?.partnerSystems);

    const noDebts = formattingSortingDebt?.length === 0;

    if (responseSortingDebts.status === 'COMPLETED') {
      setNoDebts(noDebts);
    }

    const hasUpdatedDebts = checkDebtsUpdate(formattingSortingDebt, debtsList);
    if (hasUpdatedDebts) setDebtsList(formattingSortingDebt);
  };

  const getSortingDebtsInLocalStorage = () => {
    localStorage.removeItem(constants.debts.DEBT);
    localStorage.removeItem(constants.debts.PAYMENT_OPTION);
    localStorage.removeItem(constants.debts.PRESELECTED_PAYMENT_OPTION);
    localStorage.removeItem(constants.debts.PROPERTIES.PAYMENT_DATE);

    const localDebts = localStorage.getItem(constants.debts.SORTING_DEBTS_RES);
    if (localDebts !== null) {
      const decryptedDebts = legacyBase64Decrypt(localDebts);
      const sortingDebtResponse = JSON.parse(decryptedDebts);

      const dateCacheSB = sortingDebtResponse?.date;
      if (!cacheSortingDebtIsValid(dateCacheSB)) return;

      handleUpdateDebtsList(sortingDebtResponse);
    }
  };

  const handleUnauthorized = async (data: any) => {
    const isValid = await validToken();

    if (!isValid || data?.error_slug === 'unauthorized') {
      logOut();

      isPollingActive.current = false;
      clearInterval(debtsPollingIntervalId);

      return (window.location.href = pageNames.signin.path);
    }
  };

  const setDebtTagDiscountDrop = (receivedDebtData: { partnerSystems: any[]; }) => {
    const updateLocalStorage = (data: any) => {
      localStorage.setItem(constants.debts.DEBTS_CARD_TAG_DISCOUNT_DROP, JSON.stringify(data));
    };
  
    const existingDebtData = localStorage.getItem(constants.debts.DEBTS_CARD_TAG_DISCOUNT_DROP);
  
    let debtData = {
      partnerSystems: receivedDebtData.partnerSystems.map(partner => ({
        name: partner?.name,
        identifier: partner?.identifier,
        partnerIdentifier: partner?.partnerIdentifier,
        debts: partner?.debts.map((debt: any) => ({
          contract: debt?.contract,
          id: debt?.id,
          partnerId: debt?.partnerId,
          isShowTag: false,
          paymentOptions: debt?.paymentOptions.map((paymentOption: any) => ({
            id: paymentOption?.id,
            agreementValue: paymentOption?.agreementValue,
            numberInstallment: paymentOption?.numberInstallment,
            discountPercentage: paymentOption?.discountPercentage,
            differenceValue: 0,
          })),
        }))
      }))
    };
  
    if (!existingDebtData) {
      updateLocalStorage(debtData);
      return;
    }
  
    const storedDebtData = JSON.parse(existingDebtData);
  
    debtData.partnerSystems = debtData.partnerSystems.map(debtPartner => {
      const updatedDebts = debtPartner.debts.map((receivedDebt: any) => {
        const storedPartner = storedDebtData?.partnerSystems.find((storedPartner: any) =>
          storedPartner.identifier === debtPartner.identifier &&
          storedPartner.partnerIdentifier === debtPartner.partnerIdentifier
        );
  
        if (storedPartner) {
          const storedDebt = storedPartner.debts.find((storedDebt: any) =>
            storedDebt.contract === receivedDebt.contract || storedDebt.id === receivedDebt.id
          );
  
          if (storedDebt) {
            const updatedPaymentOptions = receivedDebt.paymentOptions.map((receivedPaymentOption: any) => {
              const storedPaymentOption = storedDebt.paymentOptions.find((storedPaymentOption: any) =>
                storedPaymentOption.id === receivedPaymentOption.id
              );
  
              if (storedPaymentOption) {
                const receivedAgreementValue = receivedPaymentOption.agreementValue;
                const storedAgreementValue = storedPaymentOption.agreementValue;
  
                if (receivedAgreementValue > storedAgreementValue) {
                  return receivedPaymentOption;
                } else if (receivedAgreementValue === storedAgreementValue) {
                  return storedPaymentOption;
                } else {
                  const difference = Math.round((Math.abs(receivedAgreementValue - storedAgreementValue)) * 100) / 100;
                  if (difference >= 1) {
                    return {
                      ...receivedPaymentOption,
                      differenceValue: difference,
                    };
                  }
                }
              }
              return receivedPaymentOption;
            });

            const isShowTag = updatedPaymentOptions.some((paymentOption: any) => paymentOption.differenceValue > 0);
  
            return {
              ...receivedDebt,
              paymentOptions: updatedPaymentOptions,
              isShowTag: isShowTag,
            };
          }
        }
        return receivedDebt;
      });
  
      return {
        ...debtPartner,
        debts: updatedDebts,
      };
    });
  
    updateLocalStorage(debtData);
  };  

  const startDebtsPollingAndUpdates = () => {
    const pollingLimit = 45;

    if (!isPollingActive.current && !firstRequestSortingDebt.current) return;
    if (sortingDebtsCallTimes.current >= pollingLimit) {
      isPollingActive.current = false;
      setIsStatusPartial(false);

      clearInterval(debtsPollingIntervalId);

      return;
    }

    apiSortingDebtTrack
      .send({
        customerIdHash: user.customerIdHash,
        document: user.documento,
      })
      .then((response: SortingDebtResponse | any) => {
        handleUpdateDebtsList(response);
        sortingDebtsCallTimes.current = sortingDebtsCallTimes.current + 1;

        if (response?.status === 'COMPLETED') {
          isPollingActive.current = false;
          clearInterval(debtsPollingIntervalId);

          const formattedResponse = formatSDResponseCache(response);

          const responseEncrypt = legacyBase64Encrypt(
            JSON.stringify(formattedResponse)
          );
          localStorage.setItem(
            constants.debts.SORTING_DEBTS_RES,
            responseEncrypt
          );

          setDebtTagDiscountDrop(formattedResponse)
        }

        if (hasDebtsApiError) {
          setHasDebtsApiError(false);
        }
      })
      .catch(async (error) => {
        console.warn('[startDebtsPollingAndUpdates]: ', error);
        sortingDebtsCallTimes.current = sortingDebtsCallTimes.current + 1;

        handleUnauthorized(error);

        if (sortingDebtsCallTimes.current >= pollingLimit) {
          setHasDebtsApiError(true);
        }
      });
  };

  const runFirstRequestSortingDebt = () => {
    if (firstRequestSortingDebt.current) {
      startDebtsPollingAndUpdates();
      firstRequestSortingDebt.current = false;
    }
  };

  const runPollingSortingDebt = () => {
    if (isPollingActive.current || firstRequestSortingDebt.current) return;

    isPollingActive.current = true;
    let elapsedSeconds = 0;

    // Define interval durations
    const REQUEST_EVERY_1_SECOND = 1000; // Time between requests for the first 6 seconds
    const MAX_DURATION_FOR_1_SECOND = 6000; // Maximum duration for 1-second requests
    const REQUEST_EVERY_2_SECONDS = 2000; // Time between requests after 6 seconds

    // Function to handle debt requests
    const requestDebts = (interval: number) => {
      startDebtsPollingAndUpdates();
      elapsedSeconds += interval;
    };

    // Set the first interval for 1-second requests
    const firstIntervalId = setInterval(() => {
      if (elapsedSeconds < MAX_DURATION_FOR_1_SECOND) {
        requestDebts(REQUEST_EVERY_1_SECOND); // Requests every 1 second
      } else {
        clearInterval(firstIntervalId);
        // Set the second interval for 2-second requests
        const secondIntervalId = setInterval(() => {
          requestDebts(REQUEST_EVERY_2_SECONDS); // Requests every 2 seconds
        }, REQUEST_EVERY_2_SECONDS);
        setDebtsPollingIntervalId(secondIntervalId);
      }
    }, REQUEST_EVERY_1_SECOND);

    setDebtsPollingIntervalId(firstIntervalId);
  };

  //#region EVENTS
  const sendEventDebtLocated = (partnerSystems: PartnerSystems[]) => {
    partnerSystems?.forEach((partnerSystem) => {
      trackRedline.debts.debtLocated({ partnerSystem }, () => {
        // this is a redline debounced event. code inside this callback will be debouced together.

        GTM.trigger.debtFound({
          dividaId: partnerSystem.id,
          partner: partnerSystem.name,
        });
      });
    });
  };


  const sendEventDebtSimulated = (debts: any) => {
    debts?.forEach((debt: any) => {
      const { discountDropValue } = getDebtCardTagDiscountDrop(debt);
      trackRedline.debts.debtSimulated({
        paymentOption: debt?.paymentOptions?.[0],
        debt: { 
          ...debt,
          tagDiscountDropValue: discountDropValue,
          partner: debt.partner 
        },
      });
    });
  };

  useEffect(() => {
    const isUserLoggedIn = !!user?.documento;

    const triggerOnlyOnPaths = ['minhas-dividas', 'dividas', 'monitoramento', 'carrinho'];
    const currentPath = window?.location?.pathname;
    const currentUrlisValidForTrigger = triggerOnlyOnPaths.some((path) =>
      currentPath.includes(path)
    );

    if (!currentPath.includes('acordo-fechado'))
      localStorage.removeItem(constants.debts.CLOSED_AGREEMENT);

    if (isUserLoggedIn && currentUrlisValidForTrigger) {
      getSortingDebtsInLocalStorage();

      runFirstRequestSortingDebt();
      runPollingSortingDebt();

      return () => {
        clearInterval(debtsPollingIntervalId);
        setDebtsPollingIntervalId(null);
      };
    }
  }, [user, window?.location?.pathname]);

  return (
    <DebtsContext.Provider
      value={{
        hasDebtsApiError,
        debtsList: orderedDebts,
        debtsListSemiSkeletons,
        skeletonsCards,
        isStatusPartial,
        noDebts,
        filteredDebts,
        filterList,
        clearFilter,
        selectFilter,
        selectedFilter,
        notFoundDebtsText,
        hasFilter,
        clearNotFoundDebtsMsg,
        countdownTimer,
        hasRedirectTo,
        setHasRedirectTo,
        hasDueDate,
        setHasDueDate,
        hasAlreadyRedirected,
        setHasAlreadyRedirected,
        loadNewDebtsInLastPos,
        loadNewDebtsInRegularPos,
        isModalOfferOpen,
        setIsModalOfferOpen,
      }}
    >
      {children}
    </DebtsContext.Provider>
  );
};
