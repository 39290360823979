import { isDev } from '@libs/utils/helpers/isDev';
import { SHOffer } from '../services/sortingHat';
import { fillUrlParams } from './fillUrlParams';
import { getUrlCurrentApp } from './getUrlCurrentApp';
import { getApp } from '@libs/utils/helpers/getApplication';
import { addAutofillParam } from '@libs/utils/helpers/addAutofillParam';
import { UserType } from '../types';

type GetUrlParams = {
  offer: SHOffer;
  fireboltId: string;
  user: UserType;
};
export async function getUrl({ offer, fireboltId, user }: GetUrlParams) {
  const { content, generalInfo } = offer;
  const { slug, links, full_funnel } = content;
  const { preApproved } = generalInfo;

  const url = getUrlCurrentApp({ links, preApproved });

  if (!url) {
    console.error(`${slug} offer URL not found`);
    return '';
  }

  let completedUrl = await fillUrlParams({
    url,
    fireboltId,
    idUser: user?.id,
  });

  if (full_funnel) {
    completedUrl = addAutofillParam({
      productSlug: slug,
      url: completedUrl,
      user,
    });

    if (isDev()) {
      const domain = getApp();
      completedUrl = completedUrl
        .replace(domain, `dev.${domain}`)
        .replace('www.', '');
    }
  }

  return completedUrl;
}
