import { Conditional, Text } from '@consumidor-positivo/aurora';
import { useRedline } from "@libs/redline";
import useBillet from '@common/hooks/useBillet';
import Billet from './Billet';
import Pix from './Pix';
import Report from '@libs/atlas/components/Report';
import PaymentSectionSkeleton from './PaymentSectionSkeleton';
import { Billet as BilletProps, ClosedAgreementProps } from '../../types';
import pixletoPartnersWithPaymentOptions from '@dues/pages/Partner/helpers/pixletoPartnersWithPaymentOptions';

import './styles.scss';

interface PaymentSectionProps {
  closedAgreement: ClosedAgreementProps;
  warningRef: React.MutableRefObject<HTMLElement | null>;
  pixCode: string;
  hasBillet: boolean;
  isPixExternal: boolean;
  billet: BilletProps;
  qrCodeUrl: string;
  isPixSuccess: boolean;
  handleCopyPixCode: () => void;
  pixText: string;
  isBilletPollingActive: boolean;
  isLoadingTryAgain: boolean;
}
const PaymentSection = ({
  closedAgreement,
  warningRef,
  pixCode,
  hasBillet,
  isPixExternal,
  billet,
  qrCodeUrl,
  isPixSuccess,
  handleCopyPixCode,
  pixText,
  isBilletPollingActive,
  isLoadingTryAgain,
}: PaymentSectionProps) => {
  const { track: trackRedline } = useRedline();

  const { flowWithPix, singlePaymentMethod, paymentOption } = closedAgreement || {};

  const { shouldHideBilletSection, shouldHidePixSection } = pixletoPartnersWithPaymentOptions({
    partnerIdentifier: closedAgreement?.debt?.partner?.partnerIdentifier,
    paymentMethodName: closedAgreement?.paymentOption?.paymentMethodName,
    singlePaymentMethod: closedAgreement?.singlePaymentMethod,
  })

  const showPixSection =
    (!!flowWithPix && !singlePaymentMethod) ||
    (!!singlePaymentMethod && paymentOption.paymentMethodName === 'PIX');

  const shareOnWhatsApp = ({ code, typeCode }: any) => {
    const message = `Aqui está o código do ${typeCode} para pagamento:\n${code}`;
    const urlWhatsApp = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}`;

    trackRedline.userTracking.elementClicked({
      name: `Compartilhar: ${typeCode}`,
      location: window.location.pathname,
      elementType: 'button',
    });

    window.open(urlWhatsApp, '_blank');
  };

  const { handleCopyBilletCode, handleBilletDownload, buttonText } = useBillet({
    installment: closedAgreement?.agreement?.acordoParcelas[0],
    msUrl: closedAgreement?.debt?.partner.msUrl,
    closedAgreement,
    refCopiedWarning: warningRef,
  });

  const showGeneratingPixMessage =
    showPixSection && isPixExternal && !pixCode && !shouldHidePixSection;
  const showPixCode =
    !!pixCode && !!flowWithPix && showPixSection && !shouldHidePixSection;
  const showBilletSection =
    hasBillet && !showPixCode && !shouldHideBilletSection;

  return (
    <div className="payment-section-v2">
      <Conditional
        condition={isBilletPollingActive || isLoadingTryAgain}
        renderIf={<PaymentSectionSkeleton />}
        renderElse={
          <>
            <Conditional
              condition={showPixCode}
              renderIf={
                <Pix
                  shareOnWhatsApp={shareOnWhatsApp}
                  isPixBillet={!!hasBillet && !shouldHideBilletSection}
                  isPixExternal={isPixExternal}
                  qrCodeUrl={qrCodeUrl}
                  handleCopyPixCode={handleCopyPixCode}
                  isPixLoading={isPixExternal}
                  isPixSuccess={isPixSuccess}
                  closedAgreement={closedAgreement}
                  billet={billet}
                  pixCode={pixCode}
                  warningRef={warningRef}
                  handleCopyBilletCode={handleCopyBilletCode}
                  buttonText={buttonText}
                  handleBilletDownload={handleBilletDownload}
                  pixText={pixText}
                />
              }
            />

            <Conditional
              condition={showBilletSection}
              renderIf={
                <Billet
                  billet={billet}
                  shareOnWhatsApp={shareOnWhatsApp}
                  warningRef={warningRef}
                  handleCopyBilletCode={handleCopyBilletCode}
                  buttonText={buttonText}
                  handleBilletDownload={handleBilletDownload}
                />
              }
            />
            <Conditional
              condition={!hasBillet && !isPixExternal}
              renderIf={
                <>
                  <Report.Root type="info" border="less">
                    <Report.Text>Gerando dados do boleto</Report.Text>
                  </Report.Root>
                  <Text
                    variant="heading-micro"
                    weight="bold"
                    className="payment-section-v2__pending-billet"
                  >
                    Seu boleto ficará disponível{' '}
                    <span>em 1 dia útil via e-mail.</span>
                    <br /> Agora é só aguardar!
                  </Text>
                </>
              }
            />
            <Conditional
              condition={showGeneratingPixMessage}
              renderIf={
                <>
                  <Report.Root type="info" border="less">
                    <Report.Text>Gerando dados do Pix</Report.Text>
                  </Report.Root>
                  <Text
                    variant="heading-micro"
                    weight="bold"
                    className="payment-section-v2__pending-billet"
                  >
                    Seu código Pix ficará disponível{' '}
                    <span>em 1 dia útil via e-mail.</span>
                    <br /> Agora é só aguardar!
                  </Text>
                </>
              }
            />
          </>
        }
      />
    </div>
  );
};
export default PaymentSection;
