interface pixletoPartnersWithPaymentOptionsProps {
  partnerIdentifier: string;
  paymentMethodName?: string;
  singlePaymentMethod?: boolean;
}

const pixletoPartnersWithPaymentOptions = ({
  partnerIdentifier = '',
  paymentMethodName,
  singlePaymentMethod,
}: pixletoPartnersWithPaymentOptionsProps) => {
  const partnersWithPixleto = ['ativossa', 'cielo'];
  const shouldStartWithPixPreselected = partnersWithPixleto?.includes(partnerIdentifier);

  const shouldHidePixSection = !singlePaymentMethod && shouldStartWithPixPreselected && paymentMethodName === 'BILLET';
  const shouldHideBilletSection = !singlePaymentMethod && shouldStartWithPixPreselected && paymentMethodName == 'PIX';

  return {
    shouldStartWithPixPreselected,
    shouldHidePixSection,
    shouldHideBilletSection,
  };
};

export default pixletoPartnersWithPaymentOptions;
