import safestorage from '@libs/utils/helpers/getSafeStorage';

import useApiOnboarding from '@admission/apis/onboarding/useApiOnboarding';
import { CachedStatus } from '@admission/apis/onboarding/types';
import { useUserStore } from '@common/store/useUserStore';
import constants from '@common/constants';

export default function useEngageEmailStatus() {
  const { user, addUser } = useUserStore();
  const { apiGetEngageCustomerData } = useApiOnboarding();

  const getEmailStatus = async () => {
    const cachedStatus = await safestorage
      .getItem<CachedStatus>(constants.auth.EMAIL_STATUS);

    let isExpired = false;

    if (cachedStatus) {
      const { exp, ...emailStatus } = cachedStatus;
      isExpired = exp < Date.now();

      if (!isExpired) {
        addUser({ ...user, updateEmail: emailStatus.bounced });
      }
    }

    if (!cachedStatus || isExpired) {
      try {
        const { data } = await apiGetEngageCustomerData.send();

        if (data) {
          const { bounced } = data;
          addUser({ ...user, updateEmail: bounced });

          await safestorage.setItem<CachedStatus>(constants.auth.EMAIL_STATUS, {
            ...data,
            exp: Date.now() + 60 * 60 * 1000, // Cache for 1 hour
          });
        }
      } catch (error) {
        console.error('Failed to fetch email status:', error);
      }
    }
  };

  return { getEmailStatus };
};
