import { useRedline } from '@libs/redline';

import {
  OfferPropsReducer,
  productListViewedEventProps,
  productViewedEventProps,
  proposalStartedEventProps,
  trackElementClickedProps,
  trackElementViewedProps,
  trackModalViewProps,
} from './types';
import { getCookie } from '@libs/utils/helpers/getSafeCookies';
import { useGoogleAnalytics } from '@libs/utils/hooks/useGA4';
import { useUserStore } from '@common/store/useUserStore';
import { getRDG } from '@libs/utils/helpers/getRDG';
import { SHDecisionOutcomeReceivedProps, SHOffer } from '@libs/creditkit';

export default function useTrackOffer() {
  const { track: trackRedline } = useRedline();
  const { trackGA } = useGoogleAnalytics();

  const user = useUserStore((state) => state.user);
  const userRdg = getRDG(user.documento);

  function trackProductListFiltered(
    categoryName: string,
    listId: string,
    filteredOffers: SHOffer[]
  ) {
    const products = filteredOffers?.map((offer, index) => ({
      brand: offer?.content?.partner,
      category: categoryName,
      name: offer?.content?.title,
      variant: offer?.content?.variant,
      productId: offer?.content?.slug,
      pageId: listId,
      position: index,
    }));

    const payload = {
      listId: listId,
      filters: [
        {
          type: 'category',
          value: categoryName,
        },
      ],
      product: products,
    };
    trackRedline.ecommerce.productListFiltered(payload);
  }

  function trackRedirectRequested(
    offer: SHOffer | { content: { slug: string } },
    pathname: string,
    url: string,
    fireboltId: string
  ) {
    if (!offer) return;
    const {
      content: { slug },
    } = offer;
    const payload = {
      originId: pathname,
      redirectRequestedId: slug,
      redirectRequestedUrl: url,
      fireboltId: fireboltId,
      rdg: userRdg,
    };

    trackRedline.experience.redirectRequested(payload);
  }

  function trackProductClicked(
    offer: SHOffer | OfferPropsReducer,
    pathname: string,
    index: number,
    firebolt_id: string
  ) {
    if (!offer) return;
    const {
      content: { slug, title, category, partner, variant },
    } = offer;

    const payload = {
      correlationId: firebolt_id,
      rdg: userRdg,
      experience: 'logged',
      product: {
        pageId: pathname,
        position: index,
        productId: slug,
        name: title,
        category,
        location: pathname,
        brand: partner,
        variant,
      },
    };

    trackRedline.ecommerce.productClicked(payload);
  }

  function trackElementClicked({
    location,
    elementType,
    name,
    text,
  }: trackElementClickedProps) {
    const payload = {
      webElement: {
        location,
        elementType,
        name,
        text,
      },
    };
    trackRedline.userTracking.elementClicked(payload);
  }

  function trackElementViewed({
    location,
    elementType,
    name,
    text,
    data,
  }: trackElementViewedProps) {
    const payload = {
      webElement: {
        location,
        elementType,
        name,
        text,
        data,
      },
    };
    trackRedline.userTracking.elementViewed(payload);
  }

  function trackDecisionEnriched() {
    const payload = {
      properties: {
        partner: 'ac',
      },
    };
    trackRedline.sortingHat.decisionEnriched(payload);
  }

  function trackDecisionRequested() {
    const payload = {
      properties: {
        partner: 'ac',
      },
    };
    trackRedline.sortingHat.decisionRequested(payload);
  }

  function trackDecisionOutcomeReceived({
    decisionProducts,
    isDefaultDecision,
  }: SHDecisionOutcomeReceivedProps) {
    const payload = {
      partner: 'ac',
      workflowId: 'ac-tree-react',
      decisionProducts,
      isDefaultDecision,
    };
    trackRedline.sortingHat.decisionOutcomeReceived(payload);
  }

  function trackDecisionAccepted(payload: any) {
    trackRedline.sortingHat.decisionAccepted(payload);
  }

  function trackModalView({ location, products }: trackModalViewProps) {
    const payload = {
      location: location,
      suggestionContext: products
        ? products.map((product) => {
            return {
              partner: product?.content?.partner,
              cardSlug: product?.generalInfo?.slug,
            };
          })
        : [],
    };
    trackRedline.sortingHat.modalViewed(payload);
  }

  function trackProductViewed({
    location,
    slug,
    name,
    category,
    brand,
    variant,
    price,
    position,
  }: productViewedEventProps) {
    const payload = {
      rdg: userRdg,
      experience: 'logged',
      product: {
        location: location,
        productId: slug,
        name: name,
        category: category,
        brand: brand,
        variant: variant,
        price: Number(price) || 0,
        position: position,
      },
    };

    trackRedline.ecommerce.productViewed(payload);
  }

  function trackProductListViewed({
    listId,
    initialCategory,
    products,
  }: productListViewedEventProps) {
    if (!products) return;

    const allProducts = products?.map((product, index) => {
      const { content } = product;
      return {
        pageId: listId,
        productId: content?.slug,
        name: content?.title,
        category: content?.category,
        brand: content?.partner,
        variant: content?.variant,
        position: index,
      };
    });

    const payload = {
      correlationId: '',
      listName: listId,
      listId: listId,
      category: initialCategory,
      product: allProducts,
    };
    trackRedline.ecommerce.productListViewed(payload);
  }

  async function trackProposalStarted({
    initialSlug,
    finalSlug,
    category,
    redirectUrl,
    location,
    fireboltId,
  }: proposalStartedEventProps) {
    if (!fireboltId) return;

    const startSourceUrl = getCookie('start_source_url');

    const payload = {
      fireboltId,
      initialSlug,
      finalSlug,
      category,
      redirectUrl,
      startSourceUrl,
      location,
      rdg: userRdg,
      marketplace: 'ac',
      experience: 'logged',
    };
    trackGA({ eventName: 'proposalStarted', payload });
    return trackRedline.experience.proposalStarted(payload);
  }

  return {
    trackProductListFiltered,
    trackRedirectRequested,
    trackElementClicked,
    trackElementViewed,
    trackDecisionAccepted,
    trackDecisionOutcomeReceived,
    trackDecisionRequested,
    trackDecisionEnriched,
    trackModalView,
    trackProductClicked,
    trackProductViewed,
    trackProductListViewed,
    trackProposalStarted,
  };
}
